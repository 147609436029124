import { GraphvizOptions, graphviz } from "d3-graphviz";
import { forwardRef, useEffect, useMemo } from "react";

interface IGraphvizProps {
  /**
   * A string containing a graph representation using the Graphviz DOT language.
   * @see https://graphviz.org/doc/info/lang.html
   */
  dot: string;
  /**
   * Options to pass to the Graphviz renderer.
   */
  options?: GraphvizOptions;
  /**
   * The classname to attach to this component for styling purposes.
   */
  className?: string;
}

const defaultOptions: GraphvizOptions = {
  fit: true,
  height: 500,
  width: 500,
  zoom: false,
};

let counter = 0;
// eslint-disable-next-line no-plusplus
const getId = () => `graphviz${counter++}`;

// ref forwading so the DottyDisplay.tsx file gets  access to this custom component.
const Graphviz = forwardRef<HTMLDivElement, IGraphvizProps>(
  ({ dot, className, options = {} }: IGraphvizProps, ref) => {
    const id = useMemo(getId, []);

    useEffect(() => {
      graphviz(`#${id}`, {
        ...defaultOptions,
        ...options,
      }).renderDot(dot);
    }, [dot, id, options]);

    return <div className={className} id={id} ref={ref} />;
  }
);

export { Graphviz };
export default Graphviz;
