import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Result, Row, Skeleton, Tabs } from "antd";
import _ from "lodash";

import { AuthContext } from "context/AuthProvider";
import apiCaller from "utils/apiCaller";
import { processWorkflowForTable } from "helpers";
import AdminTable from "components/AdminTable";
import '../index.css';
const { TabPane } = Tabs;

const AdminDashboardView = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<any>({});
  const [workflows, setWorkflows] = useState([]);
  const [incompleteWorkflows, setIncompleteWorkflows] = useState<any[]>([]);
  const [completedWorkflows, setCompletedWorkflows] = useState<any[]>([]);

  useEffect(() => {
    if (currentUser.roles.includes("superadmin") || currentUser.roles.includes("admin")) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const usersData = await apiCaller.get("/api/users");

      const users = usersData.data.map((user: any) => ({
        key: user.id,
        ...user,
      }));

      const usersMap: any = {};

      for (const user of users) {
        const email = user["email"];
        const name = user["name"];
        usersMap[email] = name;
      }

      await fetchAllWorkflows(usersMap);
      setUsers(usersMap);
    } catch (err) {
      console.error("Error in fetching the users: ", err);
    }
    setLoading(false);
  };

  const fetchAllWorkflows = async (usersMap?: any) => {
    setLoading(true);
    try {
      const workflowsData = await apiCaller.get("/api/workflows");

      if (!usersMap) usersMap = users;

      const workflows = workflowsData.data.map((workflow: any) => processWorkflowForTable(workflow, usersMap));

      const sortedWorkflows: any[] = sortWorkflows(workflows);
      const [completed, incomplete]: any[] = _.partition(sortedWorkflows, ["workflowComplete", true]);

      setWorkflows(workflows);
      setCompletedWorkflows(completed);
      setIncompleteWorkflows(incomplete);
    } catch (err) {
      console.error("Error in fetching the workflows: ", err);
    }
    setLoading(false);
  };

  /**
   * Sort workflowsList based on "lastModified" time if present, else use "createdAt"
   * @param workflowList Filtered WF list
   */
  const sortWorkflows = (workflows: Array<any>) => {
    workflows.sort((a, b) =>
      "lastModified" in a && "lastModified" in b
        ? b.lastModified?._seconds - a.lastModified?._seconds
        : b.createdAt?._seconds - a.createdAt?._seconds
    );
    return workflows;
  };

  const Info = ({ title, value }: any) => (
    <div style={{ position: "relative", textAlign: "center" }}>
      <span
        style={{
          color: "rgba(0,0,0,0.45)",
          display: "inline-block",
          marginBottom: "4px",
          fontSize: "16px",
          lineHeight: "22px",
        }}
      >
        {title}
      </span>
      <p style={{ margin: 0, fontSize: "24px", lineHeight: "32px" }}>{value}</p>
    </div>
  );

  if (!(currentUser.roles.includes("superadmin") || currentUser.roles.includes("admin"))) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button key="dashboard" type="primary" onClick={() => history.push("/app/dashboard")}>
            Return to Dashboard
          </Button>
        }
      />
    );
  }

  return (
    <div>
      <Card>
        {!loading ? (
          <Row>
            <Col sm={8} xs={24}>
              <Info title="Incomplete Workflows" value={incompleteWorkflows.length} />
            </Col>
            <Col sm={8} xs={24}>
              <Info title="Completed Workflows" value={completedWorkflows.length} />
            </Col>
            <Col sm={8} xs={24}>
              <Info title="All Workflows" value={workflows.length} />
            </Col>
          </Row>
        ) : (
          <Skeleton title={false} />
        )}
      </Card>

      <Card bordered={false} style={{ marginTop: "24px" }}>
        <Tabs defaultActiveKey="incomplete">
          <TabPane tab="Incomplete Workflows" key="incomplete">
            <h1>Incomplete Workflows</h1>
            {!loading ? (
              <AdminTable data={incompleteWorkflows} usersMap={users} handleChange={fetchAllWorkflows} />
            ) : (
              <Skeleton />
            )}
          </TabPane>
          <TabPane tab="Completed Workflows" key="completed">
            <h1>Completed Workflows</h1>
            {!loading ? (
              <AdminTable data={completedWorkflows} usersMap={users} handleChange={fetchAllWorkflows} />
            ) : (
              <Skeleton />
            )}
          </TabPane>
          <TabPane tab="All Workflows" key="all">
            <h1>All Workflows</h1>
            {!loading ? (
              <AdminTable data={workflows} usersMap={users} handleChange={fetchAllWorkflows} />
            ) : (
              <Skeleton />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default AdminDashboardView;
