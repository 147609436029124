import { Steps } from "antd";

import { StepperProps } from "models";

const { Step } = Steps;

const Stepper = ({
  workflowSteps,
  allStepsInfo,
  currentStep,
  currAssignee,
  usersMap,
  wfUsers,
  logActions,
  PdfPreviewProp,
  showWfStepsStatus,
}: StepperProps) => {
  let showPdfPreview = true;
  if (currentStep === "generate_pdf" || currentStep === "complete") showPdfPreview = false;

  let currentStepNumber = 0;
  currAssignee = usersMap[currAssignee];

  let wfCreator = "";

  if (wfUsers !== null && wfUsers !== undefined) {
    wfCreator = usersMap[wfUsers[0]];
  }

  if (showWfStepsStatus === false) return <></>;

  if (currentStep === "ForcePushToSecondStep") {
    currentStepNumber = 2;
  } else {
    if (workflowSteps !== undefined) {
      for (let i = 0; i < workflowSteps.length; i++) {
        if (workflowSteps[i]["step"] === currentStep) {
          currentStepNumber = i;
          break;
        }
      }
    }
  }

  const CSSForStepper = (createTimestamp: string, wfCreator: string) => {
    const divStyle = { fontSize: "10px", margin: "0px", padding: "0px" };
    const margin = { margin: "0px", padding: "0px" };
    const text = wfCreator == null ? "Done" : "Done by " + " " + wfCreator;
    const lastIndex = createTimestamp?.lastIndexOf(":");
    const afterSlice = "(" + createTimestamp.slice(0, lastIndex) + " " + createTimestamp.slice(-2) + ")";
    return (
      <div>
        <span style={margin}>{text}</span> <span style={divStyle}>{afterSlice}</span>
      </div>
    );
  };

  return (
    <>
      <Steps direction="vertical" current={currentStepNumber}>
        {workflowSteps?.map((step: any, index: any) => {
          if (step === null) return null;

          let defaultActor: string = wfCreator;
          let desc: any = "";

          if (index === 0) {
            let createTimestamp = logActions["createTimestamp"];
            if (wfCreator !== "") {
              if (createTimestamp === undefined) {
                desc = "Done by " + " " + wfCreator;
              } else {
                desc = CSSForStepper(createTimestamp, wfCreator);
              }
            }
          } else if (index === currentStepNumber && currAssignee && currAssignee !== "") {
            desc = "To be done by" + " " + currAssignee;
          } else if (index < currentStepNumber) {
            const actor = logActions[step["step"]];
            let actorEmail: string = "";
            let timestamp: string = "";

            if (actor === undefined) {
              if (defaultActor !== "") desc = "Done by " + defaultActor;
            } else {
              actorEmail = actor["actor"];
              timestamp = actor["timestamp"];
              desc = CSSForStepper(timestamp, usersMap[actorEmail]);
              defaultActor = usersMap[actorEmail];
            }
          }
          if (
            allStepsInfo &&
            step.step &&
            allStepsInfo[step.step] &&
            (allStepsInfo[step.step].visible || allStepsInfo[step.step].visible == undefined)
          ) {
            return <Step key={index} title={step["stepNls"].replace("|", "")} description={desc} />;
          } else {
            return <></>;
          }
        })}
      </Steps>
      {showPdfPreview ? PdfPreviewProp : null}
    </>
  );
};

export default Stepper;
