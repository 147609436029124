import {
    Button,
    Card,
  } from "antd";

const WorkflowInstruction = (props: any)=> {
  const instructions = props.currentStep.params.instruction_label.split('|');
  const content = instructions.map((item: any, index: number)=> {
    return <p key={index}>{item}</p>
  })
    return (
        <>
        <Card title="Instructions:" style={{marginBottom: '20px'}}>
            <div>
              {content}
            </div>
        </Card>
        <div style={{ marginTop: 20 }}>
          <Button
            type="primary"
            onClick={()=> {
                props.onWorkflowInstructionClick();
            }}
          >
            {props.currentStep?.nextStepTitle !== ""
              ? `${props.currentStep?.nextStepTitle.split('|')[0]}`
              : null}
          </Button>
        </div>
        </>
    )
};

export default WorkflowInstruction;