import { useEffect, useRef, useState } from "react";

const events = ["keypress", "mousemove", "touchmove", "click", "scroll"];
export const useActive = (time: number) => {
  const [active, setActive] = useState(false);
  const timer: any = useRef();

  useEffect(() => {
    const handleEvent = () => {
      setActive(true);
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => {
        setActive(false);
      }, time);
    };

    events.forEach((event: string) => document.addEventListener(event, handleEvent));

    return () => {
      events.forEach((event: string) => document.removeEventListener(event, handleEvent));
    };
  }, [time]);

  return active;
};
