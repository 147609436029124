import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { Card, Cascader, Col, Input, Row } from "antd";
import "../index.css";
import apiCaller from "utils/apiCaller";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Params {
  section: string;
}
interface FileParams {
  [key: string]: string;
}

const StartWorkflowView = ({ allowedWorkflows, onChange }: any) => {
  const { section } = useParams<Params>();
  const [files, setFiles] = useState<FileParams>({});
  const [workflowData, setWorkflowData] = useState([]);

  const originalSection = section
    .replace(/-/g, " ")
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

  const relevantWorkflows = allowedWorkflows.filter(
    (workflow: any) => workflow.section.toLowerCase() === originalSection.toLowerCase()
  );

  const filter = (inputValue: any, path: any) => {
    return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  const options = relevantWorkflows.map((workflow: any) => ({
    value: workflow.name,
    label: workflow.name,
    workflowType: workflow.workflowType,
  }));

  useEffect(() => {
    setWorkflowData(options);
    if (originalSection === "Graduate Student" || originalSection === "Undergraduate") {
      generatePDF();
    }
  }, [section]);

  const onChangeHander = (event: any) => {
    const filteredWorkflows = options.filter((item: any) => {
      return item.label.toLowerCase().includes(event.target.value.toLowerCase()) === true;
    });
    setWorkflowData(filteredWorkflows);
  };
  
  const onClickCardHandler = (title: string) => {
    let selectedOption=options.filter((item: any) => {
      return item.label.toLowerCase().includes(title.toLowerCase()) === true;
    });
    onChange([title],selectedOption);
  };

  const generatePDF = async () => {
    try {
      const pdfUrls: FileParams = {};
      await Promise.all(
        relevantWorkflows.map(async (item: any) => {
          const resp = await apiCaller.get(`/api/pdf-previews`, {
            params: {
              form: item.formKey,
            },
          });
          pdfUrls[item.name] = resp.data.url;
        })
      );
      setFiles(pdfUrls);
    } catch (e) {
      console.log("Error fetching the Image URls: ", e);
    }
  };

  let content;

  if (originalSection === "Graduate Student" || originalSection === "Undergraduate") {
    content = (
      <React.Fragment>
        <Card bordered={false}>
          <h1>Start a new {originalSection} workflow.</h1>
          <Input
            placeholder="Please enter the workflow"
            allowClear={true}
            onChange={onChangeHander}
            style={{ width: 240 }}
          />
        </Card>

        <br />
        <Row gutter={16} className="row-in-workflow">
          {workflowData.map((item: any, index: any) => (
            <Col span={5} key={item.label} className="row-in-workflow-div">
              <div
              //  style={{ width: "90%" }}
              >
                <Card
                  className="our-card-main"
                  hoverable
                  cover={
                    <div className="container">
                      {files[item.label] !== "" ? (
                        <Document file={files[item.label]}>
                          {<Page width={300} height={300} scale={0.66} pageNumber={1} />}
                        </Document>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  }
                  onClick={() => onClickCardHandler(item.label)}
                  bordered={true}
                >
                  <label
                    style={{
                      wordWrap: "break-word",
                      fontSize: "small",
                      textAlign: "left",
                      position: "absolute",
                      bottom: "0",
                    }}
                  >
                    {" "}
                    {item.label}{" "}
                  </label>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  } else {
    content = (
      <React.Fragment>
        <Card bordered={false}>
          <h1>Start a new {originalSection} workflow.</h1>
          <Input
            placeholder="Please enter the workflow"
            allowClear={true}
            onChange={onChangeHander}
            style={{ width: 240 }}
          />
        </Card>
        <br />
        <Row gutter={[16, 16]} className="row-in-workflow-else">
          {workflowData.map((item: any, index: any) => (
            <Col span={5} key={item.label} className="row-in-workflow-div-else">
              <div>
                <Card
                  className="our-card-main"
                  hoverable
                  bodyStyle={{
                    textAlign: "center",
                    border: "1px solid #d9d9d9",
                    padding: "20px",
                    height:"340px",
                    backgroundColor: "white"
                  }}
                  onClick={() => onClickCardHandler(item.label)}
                  bordered={true}
                >
                  {/* {<div style={{ height: 238, backgroundColor: "white" }}></div>} */}
                  <div style={{
                      marginTop:'150px'
                    }}>
                    <label
                    style={{
                      wordWrap: "break-word",
                      textAlign: "center",
                      fontSize: "small",
                    }}
                  >
                    {item.label}
                  </label>
                  </div>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
  return <div>{content}</div>;
};

export default StartWorkflowView;
