import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";
import apiCaller from "utils/apiCaller";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFPreviewGenerate = ({ setGenerating, formName }: any) => {
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState<any | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    generatePDF();
  }, []);

  const generatePDF = async () => {
    setGenerating(true);
    const res = await apiCaller.get(`/api/pdf-previews`, {
      params: {
        form: formName,
      },
    });
    setFile(res.data.url);
    setGenerating(false);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 25,
        overflow: "auto",
      }}
    >
      {file !== "" ? (
        <>
          <Document
            file={file}
            onLoadError={(error) =>
              alert("Error while loading document! " + error.message)
            }
            onSourceError={(error) =>
              alert("Error while loading document! " + error.message)
            }
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setPageNumber(1);
            }}
          >
            {<Page width={550} scale={0.75} pageNumber={pageNumber} />}
          </Document>
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <Button
              type="default"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <LeftOutlined />
              Previous Page
            </Button>
            <Button
              type="default"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next Page <RightOutlined />
            </Button>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default PDFPreviewGenerate;
