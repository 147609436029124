import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import apiCaller from 'utils/apiCaller';

interface Next {
  yes: string;
  no: string;
}

interface User {
  id: string;
  email: string;
  name: string;
}

interface Step {
  title: string;
  function: string;
  params?: any;
  prev?: string;
  next: string | Next;
}

interface Props {
  workflowId: string;
  currentStep: Step;
  creator: string;
  onChange: (value: string) => void;
}

const { Option } = Select;

const WorkflowAssign = ({
  workflowId,
  currentStep,
  creator,
  onChange,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>();

  useEffect(() => {
    async function fetchUser() {
      let email;
      if (
        !currentStep.params.assignee.includes('@') &&
        currentStep.params.assignee !== 'creator'
      ) {
        const res = await apiCaller.get('/api/usersByRole', {
          params: { role: currentStep.params.assignee },
        });
        // console.log(res.data);
        if (res.data.length === 1 && res.data[0].email) {
          onChange(res.data[0].email);
        }

        setUsers(res.data);
      } else {
        if (currentStep.params.assignee === 'creator') {
          email = creator;
          onChange(email);
        } else {
          email = currentStep.params.assignee;
        }
        const res = await apiCaller.get('/api/user', {
          params: {
            email,
          },
        });
        // console.log(res.data);
        if (res.data.email) {
          onChange(res.data.email);
        }
        setUsers([{ ...res.data }]);
      }

      setLoading(false);
    }
    fetchUser();

    // async function loadUsers() {
    //   const res = await apiCaller.get('/api/users', {});
    //   setUsers(res.data);
    // }
    // loadUsers();
  }, []);

  const handleChange = async (value: string) => {
    onChange(value);
  };

  if (loading) {
    return null;
  }

  function compareStrings(a: string, b: string) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  }
  users?.sort((a, b) => compareStrings(a.name, b.name));
  const options = users?.map((u: User) => (
    <Option key={u.id} value={u.email}>
      {u.name} - {u.email}
    </Option>
  ));

  return (
    <Select
      showSearch
      style={{ width: 500 }}
      placeholder='Search to Select'
      optionFilterProp='children'
      defaultValue={users?.length === 1 ? users[0].email : undefined}
      disabled={options?.length === 1}
      filterOption={(input, option) => {
        const options = option?.children.map((o: string) => o.toLowerCase());
        return options.find((opt: string) => opt.includes(input.toLowerCase()));
      }}
      filterSort={(optionA, optionB) => {
        return optionA.value
          .toLowerCase()
          .localeCompare(optionB.value.toLowerCase());
      }}
      onChange={handleChange}
    >
      {options}
    </Select>
  );
};

export default WorkflowAssign;
