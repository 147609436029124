import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Space, Drawer } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormBuilder from 'antd-form-builder';
import apiCaller from 'utils/apiCaller';
import moment from 'moment';
import _ from 'lodash';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  sbuId: string;
  evaluation: string;
}

interface EditableTableState {
  dataSource: DataType[];
  count: number;
}

type Columns = (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface Props {
  workflowId: string;
  params: {
    csvStep: string;
    columns: any[];
    form: any;
  };
}
const WorkflowMultiform = ({ workflowId, params }: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState(2);
  const [selectedRow, setSelectedRow] = useState<any>();

  const [form] = Form.useForm();
  const forceUpdate = FormBuilder.useForceUpdate();
  const [meta, setMeta] = useState(params.form);

  useEffect(() => {
    async function fetchCSV() {
      const res = await apiCaller.get('/api/csv-subcollection', {
        params: { workflowId, csvStep: params.csvStep },
      });
      let data = res.data;
      if (data.length > 0) {
        // data = data.map((d: any, i: number) => ({ ...d, key: i }));
        setData(data);
        // const cols = Object.keys(data[0]);
        // // used for table column display
        // const tableCols = cols.map((col: string) => ({
        //   title: col,
        //   dataIndex: col
        //     .toLowerCase()
        //     .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
        //   key: col
        //     .toLowerCase()
        //     .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
        // }));
        // setColumns(tableCols);

        // // the data source for the table, converting the data indices to camelCase
        // const dataSource = data.map((d: any) =>
        //   _.mapKeys(d, (value, key) => _.camelCase(key))
        // );
        // setData(dataSource);
      }
    }
    fetchCSV();
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ evaluation: selectedRow?.evaluation ?? '' });
  }, [meta]);

  const columns: Columns = [
    ...params.columns,
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record: any) => (
        <Space>
          <a onClick={() => handleEdit(record.key)}>Edit</a>
          {data.length >= 1 ? (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record.key)}
            >
              <Button type='text' danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  const handleEdit = (key: React.Key) => {
    const dataSource = [...data];

    const selectedData = dataSource.find((item) => item.key === key);

    let metaClone = meta;
    if (selectedData) {
      metaClone = _.cloneDeep(meta);
      let populatedForm = metaClone.fields;
      for (let [key, value] of Object.entries(selectedData)) {
        let index;
        if (
          (index = populatedForm.findIndex((f: any) => f.key === key)) != -1
        ) {
          if (populatedForm[index].widget === 'date-picker') {
            populatedForm[index].initialValue = moment(selectedData);
          } else {
            populatedForm[index].initialValue = value;
          }
        }
      }
    }

    setMeta(metaClone);
    setSelectedRow(selectedData);
  };

  const handleDelete = (key: React.Key) => {
    const dataSource = [...data];
    setData(dataSource.filter((item) => item.key !== key));
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      name: `Edward King ${count}`,
      sbuId: `${count * 1000000000}`,
      evaluation: count % 2 == 0 ? 'Wonderful TA!' : 'Horrible TA!',
    };
    setData([...data, newData]);
    setCount(count + 1);
  };

  const handleSave = async (values: any, selectedRow: any) => {
    const newData = [...data];
    const index = newData.findIndex((item) => selectedRow.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...values,
    });
    // console.log('NEW DATA', newData);

    await apiCaller.post('/api/multiform-save', {
      workflowId,
      formData: newData,
      csvStep: params.csvStep,
    });
    setData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const cols = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const handleDrawerClose = () => {
    setSelectedRow(undefined);
  };

  return (
    <div>
      <Button onClick={handleAdd} type='primary' style={{ marginBottom: 16 }}>
        Add a row
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={data}
        columns={cols as ColumnTypes}
      />
      <Drawer
        title='Evaluate TA'
        width={720}
        onClose={handleDrawerClose}
        visible={!!selectedRow}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit' form='workflow-multiform'>
              Save
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          onValuesChange={forceUpdate}
          layout='vertical'
          id='workflow-multiform'
          onFinish={(values: any) => {
            handleSave(values, selectedRow);
            handleDrawerClose();
          }}
        >
          <FormBuilder meta={meta} form={form} />
        </Form>
      </Drawer>
    </div>
  );
};

export default WorkflowMultiform;
