import React, { useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

import { AuthContext } from "context/AuthProvider";
import Loader from "./Loader";

interface Props {
  component: React.ComponentType<RouteComponentProps<any>>;
  path?: string;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { authenticated, loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) return <Loader />;

  return (
    <Route {...rest} render={(routeProps) => (authenticated ? <Component {...routeProps} /> : <Redirect to="/" />)} />
  );
};

export default PrivateRoute;
