import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, message, Table, Modal, Space, Input } from "antd";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import OurTable from "components/workflow/OurTable";
import '../../index.css';
import apiCaller from "utils/apiCaller";
import { AuthContext } from "context/AuthProvider";
import Loader from "components/Loader";

const { Search } = Input;
const auth = getAuth();

const Impersonation = () => {
  const { currentUser, activeUser } = useContext(AuthContext);
  const history = useHistory();
  const durationInSeconds = 2;
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [recentlyImpersonated, setRecentlyImpersonated] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchRecentlyImpersonated = async () => {
    setVisible(true);
    setLoading(true);
    try {
      const userData = await apiCaller.get("/api/user", {
        params: { email: activeUser.email },
      });

      let recentlyImpersonated = [];

      if (userData?.data?.impRoles.length !== 0) {
        const impersonatedData = await apiCaller.get("/api/recently-impersonated");
        recentlyImpersonated = impersonatedData.data;
      }

      setRecentlyImpersonated(recentlyImpersonated);
    } catch (err) {
      console.error("Error in fetching recently impersonated data: ", err);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      render: (user: any) => <Button onClick={() => impersonate(user.id)}>Impersonate</Button>,
    },
  ];

  const recentColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Last Impersonated",
      dataIndex: "impersonatedAt",
      key: "impersonatedAt",
      render: (impersonatedAt: any) => new Date(impersonatedAt._seconds * 1000).toLocaleDateString(),
    },
    {
      title: "Actions",
      render: (user: any) => <Button onClick={() => impersonate(user.id)}>Impersonate</Button>,
    },
  ];

  

  const impersonate = async (userId: string) => {
    try {
      const impersonateRes = await apiCaller.post("/api/impersonate", { userId, currentUserId: currentUser?.id }, {});

      if (impersonateRes.data) {
        signInWithCustomToken(auth, impersonateRes.data).then(async (userCredential) => {
          await auth.currentUser?.getIdTokenResult();
          setVisible(false);
          history.push("/app/dashboard");
        });
      } else {
        message.error("Could not successfully impersonate!");
      }
    } catch (err) {
      console.error("Error in impersonating: ", err);
    }
  };

  const unimpersonate = async () => {
    try {
      const unimpersonateRes = await apiCaller.post("/api/unimpersonate", {
        userId: currentUser.id,
      });

      if (unimpersonateRes.data) {
        signInWithCustomToken(auth, unimpersonateRes.data).then((userCredential) => {
          message.success("You have successfully stopped impersonation!",durationInSeconds);
          history.push("/app/dashboard");
        });
      } else {
        message.error("Failed to stop impersonation!");
      }
    } catch (err) {
      console.error("Error in unimpersonating: ", err);
    }
  };

  const onSearch = async () => {
    setLoading(true);
    try {
      const trimSearch = search.trim();
      if (trimSearch === "") return;
      const res = await apiCaller.get("/api/search", {
        params: { queryText: trimSearch },
      });

      let counter = 0;
      const users = res.data.map((u: any) => {
        u.key = counter++;
        return u;
      });
      setUsers(users);
    } catch (err) {
      console.error("Error in searching for user: ", err);
    }
    setLoading(false);
  };

  if (
    !currentUser.roles.includes("superadmin") &&
    !currentUser.roles.includes("admin") &&
    currentUser.impRoles.length === 0
  ) {
    return null;
  }

  return (
    <div className="smaller-button">
    {
      currentUser.id === activeUser.id ? (
        <Button onClick={fetchRecentlyImpersonated}>Impersonate</Button>
      ) : (
        <Button onClick={unimpersonate}>Stop Impersonation</Button>
      )
    }

      <Modal
        width={1000}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Space>
          <Search
            placeholder="Enter Name/Email"
            size="middle"
            className="searchbox-impersonation"
            style={{
              minWidth: "280px",
              width:"400px",
              marginBottom: "20px",
            }}
            onChange={(event) => setSearch(event.target.value)}
            onSearch={onSearch}
          />
        </Space>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Table
            className="impersonation-table"
              dataSource={recentlyImpersonated}
              columns={recentColumns}
              bordered
              title={() => "Recently Impersonated"}
            />
            <Table className='impersonation-table'
            dataSource={users} columns={columns} bordered title={() => "Search Results"} />

          </>
        )}
      </Modal>
    </div>
  );
};

export default Impersonation;
