import React, { useEffect, useState, useContext } from "react";
import { Button, message, Card, Skeleton } from "antd";
import apiCaller from "utils/apiCaller";
import ReactLoading from "react-loading";
import { AuthContext } from "context/AuthProvider";
import { useLocation } from "react-router-dom";


interface State {
  scheduleEvent?: boolean
}

const WorkflowSendCalendarInvites = (props: any) => {
  const durationInSeconds = 2;
  const location = useLocation<State>(); // state.form contains the type of form we would use
  const state = location.state;
  const [eventInfo, setEventInfo] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { currentUser, activeUser } = useContext(AuthContext);
  let content: any;
  useEffect(() => {
    async function scheduleEvent() {
      const workflowId = props.workflowId;
      const docName = props.workflow?.currentStep;
      const res = await apiCaller.get("/api/calendar-events", {
        params: { workflowId: workflowId, docName: docName },
      });
      if (res.data) {
        const eventData = res.data;
        const resp = await apiCaller.post("/api/schedule-calendar-event", {
          event: eventData,
          docName: docName,
          workflowId: workflowId
        });
        if (resp.data.status === "confirmed") {
          props.onCalendarInvitesHandler();
          message.success("You have successfully scheduled Calendar Event",durationInSeconds);
        } else {
          message.error(
            "Failed to Schedule Calendar event. Please contact the Admin",durationInSeconds
          );
        }
      }
    }
    async function getCalendarEvents() {
      const workflowId = props.workflowId;
      const docName = props.workflow?.currentStep;
      const resp = await apiCaller.get("/api/calendar-events", {
        params: { workflowId: workflowId, docName: docName },
      });
      if (resp.data) {
        setEventInfo(resp.data);
        setLoading(false);
      }
    }
    if (state && state.scheduleEvent) {
      state.scheduleEvent = false;
      scheduleEvent();
    } else {
      getCalendarEvents();
    }
  }, []);

  const getFormattedDate = (dateTimeString: string)=> {
    const date = new Date(dateTimeString);

    const options: any = {
      timeZone: 'America/New_York',
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    // Format the output based on the Eastern Time Zone
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };

  const onValidateApi = async () => {
    const userData = await apiCaller.get("/api/user", {
      params: { email: currentUser.email },
    });
    if (userData?.data?.calendarApiRefreshToken) {
      const docName = props.workflow?.currentStep;
      const workflowId = props.workflowId;
      const resp = await apiCaller.post("/api/schedule-calendar-event", {
        event: eventInfo,
        docName: docName,
        workflowId: workflowId
      });
      if (resp.data.status === "confirmed") {
        props.onCalendarInvitesHandler();
        message.success("You have successfully scheduled Calendar Event",durationInSeconds);
      } else {
        message.error(
          "Failed to Schedule Calendar event. Please contact the Admin",durationInSeconds
        );
      }
    } else {
      const workflowId = props.workflowId;
      const response = await apiCaller.post("/api/access-calendar", {
        workflowId: workflowId
      });
      
      window.location.href = response.data.authUrl;
    }
  };

  if (loading) {
    content = (
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: 24 }}>Loading, please wait...</span>
          <ReactLoading type="spokes" color="#990000" />
        </div>
        <Skeleton active />
      </Card>
    );
  } else {
    content = (
      <>
        <Card title="Instructions:" style={{ marginBottom: "20px" }}>
          <h2>Calendar Event Details</h2>
          <br />
          <h4>
            Event Title: {" "}
            {eventInfo?.summary}
          </h4>
          <br />
          <h4>
            Event Location: {" "}
            {eventInfo?.location}
          </h4>
          <br />
          <h4>
            Event Start Time: {" "}
            {getFormattedDate(eventInfo?.start.dateTime)}
          </h4>
          <br />
          <h4>
            Event End Time: {" "}
            {getFormattedDate(eventInfo?.end.dateTime)}
          </h4>
          <br />
          {eventInfo && eventInfo?.conferenceData ? (
            <div>
              <h4>
                Zoom Link: {eventInfo?.conferenceData?.entryPoints[0]?.uri}
              </h4>
              <h4>
                Zoom PassCode: {" "}
                {eventInfo?.conferenceData?.entryPoints[0]?.passcode}
              </h4>
            </div>
          ) : (
            ""
          )}
        </Card>
        <div>
          {props.prevButton}
          <Button style={{"marginLeft": "20px"}} type="primary" onClick={onValidateApi}>
            Send Invite
          </Button>
        </div>
      </>
    );
  }

  return <>{content}</>;
};

export default WorkflowSendCalendarInvites;
