import { Table, DatePicker, Input, Cascader, Button, Card } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState, useContext } from "react";
import { CSVLink } from "react-csv";
import apiCaller from "utils/apiCaller";
import moment from "moment-timezone";
import { AuthContext } from "context/AuthProvider";
import OurTable from "./OurTable";
import '../../index.css';
const { Search } = Input;

const Aggregate = ({ formKey }: any) => {
  const { activeUser } = useContext(AuthContext);
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<any[]>([]);
  const [defaultStartDate, setStartDate] = useState<any>();
  const [defaultEndDate, setEndDate] = useState<any>();
  const [allSemesters, setAllSemesters] = useState<any[]>([]);
  const [currentSemester, setCurrentSemester] = useState<any>();

  useEffect(() => {
    if (defaultStartDate && defaultEndDate) {
      handleDatePick([defaultStartDate, defaultEndDate]);
    }
  }, [defaultStartDate]);

  useEffect(() => {
    const currentSemester = allSemesters.find((semester) => {
      const currentYear = moment().year();
      const currentMonth = moment().month();
      return semester.year === currentYear && currentMonth >= semester.startMonth && currentMonth <= semester.endMonth;
    });

    setCurrentSemester(currentSemester)
    setSemFilters(currentSemester?.name);
  }, [allSemesters])

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const params: any = {
        formKey: formKey,
      };
      const result = await apiCaller.get("/api/aggregate", {
        params,
      });
      const fdata = result.data.data;
      let cols = result.data.columns;
      cols = createColumnsConfig(cols);
      // The following map is to replace all double quotes to single quotes as react-csv breaks strings at double quotes.
      const data = fdata.map((element: any) => {
        let row: any = {};
        for (let i = 0; i < cols.length; i++) {
          let val = String(element[cols[i].title]);
          if (val !== "undefined") {
            val = val.replaceAll(/["]/g, "'");
          }
          row[cols[i].title] = `${val === "undefined" ? "" : val}`;
        }
        return row;
      });
      setColumns(cols);
      setData(data);
      setLoading(false);
    };

    fetchData().then(Response => {
      setAllSemesters(generatePastSemesters());
    });
  }, [formKey]);

  function createColumnsConfig(columnsData : any) {
    return columnsData.map((element : any) => {
      // filter for semesters
      if (element === 'semesterSeason') {
        return {
          title: element,
          dataIndex: element,
          filters: [
            { text: 'Spring', value: 'Spring' },
            { text: 'Summer', value: 'Summer' },
            { text: 'Fall', value: 'Fall' },
            { text: 'Winter', value: 'Winter' },
          ],
          onFilter: (value : any, record : any) => record[element] === value,
        };
      } else {
        return {
          title: element,
          dataIndex: element,
        };
      }
    });
  }

  const generatePastSemesters = () => {
    const currentYear = moment().year();
    const semesters = [];
    for (let i = 0; i < 3; i++) {
      semesters.push(
        { name: `Spring ${currentYear - i}`, startMonth: 0, endMonth: 4, year: currentYear - i },
        { name: `Summer ${currentYear - i}`, startMonth: 5, endMonth: 6, year: currentYear - i },
        { name: `Fall ${currentYear - i}`, startMonth: 7, endMonth: 11, year: currentYear - i }
      );
    }
    return semesters;
  };

  const setSemFilters =  (selectedSemester: string | undefined) => {
    if (selectedSemester) {
      const selectedSemesterObj = allSemesters.find((semester) => semester.name === selectedSemester);
      if (selectedSemesterObj) {
        const startDate = moment().year(selectedSemesterObj.year).month(selectedSemesterObj.startMonth).startOf('month');
        const endDate = moment().year(selectedSemesterObj.year).month(selectedSemesterObj.endMonth).endOf('month');
        setStartDate(startDate);
        setEndDate(endDate);
      }
    }
  };

  const handleDatePick = (dates: any) => {
    if (dates) {
      const [startDate, endDate] = dates;
      startDate.startOf("day");
      endDate.endOf("day");
      const filteredData = data.filter((record: any) => {
        const localTime = record.examDate? new Date(record.examDate.split(',')[0]) : new Date(record.dateApplied); //filter by exam Start time for RPE and Date Applied for TA
        const dateInGMT = new Date(localTime.valueOf() + localTime.getTimezoneOffset() * 60000);

        const date = moment(dateInGMT);
        if (date >= startDate && date <= endDate) {
          return true;
        }
        return false;
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFilteredData = data.filter((row: any) =>
      Object.keys(row).some((v: any) => {
        if (v === "name" || v === "createdBy") {
          return `${row[v]}`
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      })
    );
    setFilteredData(newFilteredData);
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Card bordered={false} bodyStyle={{ padding: "0" }}>
        <div id="container" style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
          <Button
            type="primary"
            style={{ alignItems: "center"}}
            shape="round"
            icon={<DownloadOutlined />}
          >
            <CSVLink
            className="csv-link"
            style={{ color: "white"}}
            data={filteredData as any}
            filename={`${formKey}-${new Date().toLocaleString()}.csv`}
            >
            Download CSV
            </CSVLink>
          </Button>
        </div>

        <div style= {{ marginBottom: "20px"}}>
        <div className="button-rpe">
        <Search
          className="button-rpe-search"
          placeholder="Search By Name / createdBy"
          // style={{ float: "left", width: "35%", marginRight: "10px"}}
          enterButton="Search"
          onChange={handleSearch}
          allowClear={true}
          />
          {defaultStartDate && (
          <Cascader
            className="button-rpe-cascader"
            options={allSemesters.map((semester) => ({ value: semester.name, label: semester.name }))}
            onChange={(value) => setSemFilters(value[0].toString())}
            placeholder="Select Semester"
            // style={{ width: "20%"}}
            defaultValue={currentSemester ? [currentSemester.name] : undefined}
          />)}
           
          {defaultStartDate && defaultEndDate && (
            <DatePicker.RangePicker
              className="button-rpe-datepicker"
              // style={{ float: "right", width : "40%"}}
              defaultValue={[defaultStartDate, defaultEndDate]}
              onChange={handleDatePick}
            />
          )}
          </div>
{/* 
          <Table dataSource={filteredData} 
          columns={columns} 
          showHeader 
          showSorterTooltip
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          /> */}
          <OurTable data={filteredData} columns={columns} activeUser={activeUser} ></OurTable>
        </div>
        </Card>
      )}
    </div>
  );
};

export default Aggregate;
