import { Button, Card, Skeleton, message } from "antd";
import { useEffect, useState, useRef } from "react";
import apiCaller from "utils/apiCaller";
import ReactLoading from "react-loading";
import { CopyOutlined } from "@ant-design/icons";

const WorkflowMailTo = (props: any) => {
  const durationInSeconds=2;
  const [loading, setLoading] = useState(true);
  const textBoxRef = useRef(null);
  const textAreaRef = useRef(null);
  const [subjectVal, setSubjectVal] = useState("");
  const [textVal, setTextVal] = useState("");
  const [mailToLink, setmailToLink] = useState<any>();
  let content: any;

  useEffect(() => {
    async function getMailToTemplate() {
      const workflowObj = { ...props.workflow };
      delete workflowObj.steps;

      const resp = await apiCaller.get("/api/get-mailto-template", {
        params: {
          recipientList: props.currentStep.params.to,
          emailTemplate: props.currentStep.params.emailTemplate,
          workflowId: props.workflowId,
          workflow: workflowObj,
        },
      });

      if (resp.data) {
        const { recipients, subject, body } = resp.data;
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);

        const mailToLink = `mailto:${recipients.join(",")}?subject=${encodedSubject}&body=${encodedBody}`;
        setSubjectVal(subject);
        setTextVal(body);
        setmailToLink(mailToLink);
        setLoading(false);
      }
    }
    if (!props.currentStep.params?.mailToParams) {
      getMailToTemplate();
    } else {
      const mailToSubject = props.currentStep.params?.mailToParams.subject;
      const recepientList = props.currentStep.params?.mailToParams.recipients;
      const mailToBody = props.currentStep.params?.mailToParams.body;
      const mailToLink = `mailto:${recepientList.join(",")}?subject=${encodeURIComponent(
        mailToSubject
      )}&body=${encodeURIComponent(mailToBody)}`;
      setSubjectVal(mailToSubject);
      setTextVal(mailToBody);
      setmailToLink(mailToLink);
      setLoading(false);
    }
  }, []);

  const handleTextCopyClick = async () => {
    if (textBoxRef.current) {
      (textBoxRef.current as any).select();
      await navigator.clipboard.writeText((textBoxRef.current as any).value);
      message.success("Text copied to clipboard",durationInSeconds);
    } else {
      message.error("No text to copy",durationInSeconds);
    }
  };

  const handleTextAreaCopyClick = async () => {
    if (textAreaRef.current) {
      (textAreaRef.current as any).select();
      await navigator.clipboard.writeText((textAreaRef.current as any).value);
      message.success("Text copied to clipboard",durationInSeconds);
    } else {
      message.error("No text to copy",durationInSeconds);
    }
  };

  const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSubjectVal(e.target.value);
  };

  const onEmailBodyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextVal(e.target.value);
  };

  if (loading) {
    content = (
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: 24 }}>Loading, please wait...</span>
          <ReactLoading type="spokes" color="#990000" />
        </div>
        <Skeleton active />
      </Card>
    );
  } else {
    content = (
      <>
        <Card title="Instructions:" style={{ marginBottom: "20px" }}>
          <div>
            <div>Click on the link generated below to send Mail for the Announcement.</div>
            <div>
              Click here to send{" "}
              <a href={mailToLink} target="_blank" rel="noopener noreferrer">
                Email Announcement
              </a>
            </div>
            <br />
            <div>
              <strong>Note:</strong>
              <br />
              If you don't have mailto protocol enabled in your browser you can copy the subject and message displayed
              below by clicking on the copy to clipboard icon near the each text box
            </div>
            <br />
            <div>
              <strong>Subject:</strong>
              <br />
              <input size={60} ref={textBoxRef} value={subjectVal} onChange={onSubjectChange} />
              <Button
                style={{ color: "#1890ff", borderColor: "#1890ff", marginTop: "5px", display: "block" }}
                onClick={handleTextCopyClick}
                icon={<CopyOutlined />}
              >
                Copy to Clipboard
              </Button>
            </div>
            <br />
            <div>
              <strong>Email Body:</strong>
              <br />
              <textarea ref={textAreaRef} value={textVal} rows={20} cols={60} onChange={onEmailBodyChange} />
              <Button
                style={{ color: "#1890ff", borderColor: "#1890ff", display: "block" }}
                onClick={handleTextAreaCopyClick}
                icon={<CopyOutlined />}
              >
                Copy to Clipboard
              </Button>
            </div>
          </div>
        </Card>
        <div style={{ marginTop: 20 }}>
          {props.prevButton}
          <Button
            style={{ marginTop: "20px", marginLeft: "20px" }}
            type="primary"
            onClick={() => {
              props.onWorkflowMailToComplete();
            }}
          >
            {props.currentStep?.nextStepTitle !== "" ? `${props.currentStep?.nextStepTitle.split('|')[0]}` : null}
          </Button>
        </div>
      </>
    );
  }

  return <>{content}</>;
};

export default WorkflowMailTo;
