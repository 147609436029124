import { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";
import { Menu } from "antd";
import { ApartmentOutlined, DashboardOutlined } from "@ant-design/icons";

import { AuthContext } from "context/AuthProvider";

const { SubMenu } = Menu;

interface Props {
  match: any;
  allowedWorkflows: any[];
}

const SideMenu = ({ match, allowedWorkflows }: Props) => {
  const history = useHistory();

  const { activeUser } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);

  const sideBarStorage = sessionStorage.getItem("sideBarMenuKey");
  const [sideBarMenuKey, setSideBarMenuKey] = useState(sideBarStorage ? sideBarStorage : "normal-dashboard");

  const isAdmin = activeUser.roles.includes("superadmin") || activeUser.roles.includes("admin");

  useEffect(() => {
    sessionStorage.setItem("sideBarMenuKey", sideBarMenuKey);
  }, [sideBarMenuKey]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP" || history.action === "PUSH") {
        setSideBarMenuKey(sectionMap.get(location.pathname));
      }
    });
  }, [history]);

  const uniqueSections = allowedWorkflows
    .filter((workflow) => {
      if (workflow.roles.includes("user")) return true;
      return activeUser?.roles.some((role: any) => workflow?.roles.includes(role));
    })
    .map((workflow: any) => workflow.section)
    .filter((v, i, s) => s.indexOf(v) === i)
    .sort();

  const sectionMap = new Map();

  // Adding sections with their tab keys
  sectionMap.set(`${match.url}/dashboard/admin`, "admin-dashboard");
  sectionMap.set(`${match.url}/dashboard`, "normal-dashboard");

  for (const section in uniqueSections) {
    const sectionUrl = `${match.url}/workflow/${uniqueSections[section].toLowerCase().replace(/\s+/g, "-")}`;
    sectionMap.set(sectionUrl, uniqueSections[section]);
  }

  return (
    <Menu
      selectedKeys={[sideBarMenuKey]}
      defaultOpenKeys={["dashboard", "workflow"]}
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      style={{ width: "inherit" }}
    >
      {isAdmin ? (
        <SubMenu key="dashboard" icon={<DashboardOutlined />} title="Dashboard">
          <Menu.Item key="normal-dashboard" onClick={() => setSideBarMenuKey("normal-dashboard")}>
            <Link to={`${match.url}/dashboard`}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="admin-dashboard" onClick={() => setSideBarMenuKey("admin-dashboard")}>
            <Link to={`${match.url}/dashboard/admin`}>Admin Dashboard</Link>
          </Menu.Item>
        </SubMenu>
      ) : (
        <Menu.Item key="dashboard" icon={<RiDashboardFill />} onClick={() => setSideBarMenuKey("normal-dashboard")}>
          <Link to={`${match.url}/dashboard`}>Dashboard</Link>
        </Menu.Item>
      )}

      <SubMenu key="workflow" icon={<ApartmentOutlined />} title="Start Workflow">
        {uniqueSections.map((section) => (
          <Menu.Item
            key={section}
            style={{ whiteSpace: "normal", height: "auto", lineHeight: "30px" }}
            onClick={() => setSideBarMenuKey(section)}
          >
            <Link to={`${match.url}/workflow/${section.toLowerCase().replace(/\s+/g, "-")}`}>{section}</Link>
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu>
  );
};

export default SideMenu;
