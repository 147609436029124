import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Loading...</h1>
      <ReactLoading type="spinningBubbles" color="#990000" />
    </div>
  );
};

export default Loader;
