import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Radio, RadioChangeEvent, Space, Table, Typography, message } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import CSVReader, { IFileInfo } from "react-csv-reader";
import { Data } from "react-csv/components/CommonPropTypes";
import apiCaller from "utils/apiCaller";

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
  dynamicTyping: true,
};

interface Column {
  title: string;
  dataIndex: string;
  key: string;
}

interface Props {
  params: {
    columns: string[];
    type: string;
    target: string;
    showExisting: boolean;
    workflowFile?: string;
    formStep?: string;
    filters?: { [key: string]: string[] };
  };
  prevButton: React.ReactNode;
  nextStepTitle: string;
  onComplete: (data: any) => void;
}

function WorkflowCSV({ params, nextStepTitle, prevButton, onComplete }: Props) {
  const durationInSeconds = 2;
  const { columns: csvColumns, type, target, showExisting, workflowFile, formStep, filters: filterOptions } = params;
  // console.log('Inside WorkflowCSV');
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<Column[]>();
  const [data, setData] = useState<any[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [readerKey, setReaderKey] = useState(Date.now());

  const changeFilter = (filter: string, option: string) => {
    let newFilters = Object.assign({}, filters);
    newFilters[filter] = option;
    setFilters(newFilters);
  };
  useEffect(() => {
    if (!csvColumns) {
      message.warning("Invalid workflow file - no CSV columns found", durationInSeconds);
    }
    async function fetchData() {
      // console.log('fetchData called');
      const res = await apiCaller.get(`/api/csv`, {
        params: { target, workflowFile, formStep, ...filters },
      }); // target is either 'students' or 'faculty'
      let resData = res.data;

      if (resData && resData.length > 0) {
        resData = resData.map((row: any) => {
          if (row.roles && row.impRoles) {
            return {
              ...row,
              roles: row.roles.join("|"),
              impRoles: row.impRoles.join("|"),
            };
          }
          return { ...row };
        });
        // console.log(resData, 'resData')
        setData(resData);
      } else {
        setData([]);
      }
    }

    const tableCols = csvColumns.map((col: string) => ({
      title: col,
      dataIndex: col.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
      key: col.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
    }));
    setColumns(tableCols);
    if (type === "download" || showExisting) {
      fetchData();
    }
    setLoading(false);
  }, [filters]);

  const adminEmails = [
    // Predefined admin user roles and impRoles
    {
      email: "mantsingh@cs.stonybrook.edu",
      roles: "grad|g1|admin|superadmin",
      impRoles: "admin|superadmin",
    },
    {
      email: "pbagal@cs.stonybrook.edu",
      roles: "grad|g1|admin|superadmin",
      impRoles: "admin|superadmin",
    },
    {
      email: "sampatil@cs.stonybrook.edu",
      roles: "grad|g1|admin|superadmin",
      impRoles: "admin|superadmin",
    },
    {
      email: "anphilip@cs.stonybrook.edu",
      roles: "grad|g1|admin|superadmin",
      impRoles: "admin|superadmin",
    },

    {
      email: "mkhamkar@cs.stonybrook.edu",
      roles: "grad|g1|admin|superadmin",
      impRoles: "admin|superadmin",
    },

    {
      email: "mferdman@cs.stonybrook.edu",
      roles: "grad|g1|admin|superadmin",
      impRoles: "admin|superadmin",
    },
  ];

  const handleUpload = (data: any[], fileInfo: IFileInfo) => {
    if (data.length > 0) {
      // Convert the column names from the CSV into the expected format for the application
      const cols = Object.keys(data[0]);
      const tableCols = cols.map((col: string) => ({
        title: col,
        dataIndex: col.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
        key: col.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
      }));

      setColumns(tableCols);

      // Iterate over each record in the uploaded data
      const dataSource = data.map((d: any) => {
        // Transform the keys into the format used by the state (camelCase)
        let transformedData = _.mapKeys(d, (value, key) => _.camelCase(key));

        // Check if the email of the current record matches any in the adminEmails array
        const adminUser = adminEmails.find(
          (admin) => admin.email.toLowerCase() === transformedData.email.toLowerCase()
        );
        if (adminUser) {
          // If the record is an admin, preserve their existing roles and impRoles
          transformedData.roles = adminUser.roles;
          transformedData.impRoles = adminUser.impRoles;
          // If the record is not an admin, process it as per your application's needs
          // (e.g., updating roles or impRoles based on the CSV data)
        } else {
          // Check if the user is an undergraduate or graduate and update their role accordingly
          const roleTypes = ["undergrad|", "grad|"]; // List of role types to check
          roleTypes.forEach((roleType) => {
            if (transformedData.roles.startsWith(roleType)) {
              const currentRoleParts = transformedData.roles.split("|");
              const newRoleParts = d.Roles.split("|"); // Assuming 'Roles' is the column name from the CSV
              if (newRoleParts[0] === roleType.slice(0, -1) && newRoleParts.length > 1) {
                // Replace the level with the new one from the CSV
                currentRoleParts[1] = newRoleParts[1];
                transformedData.roles = currentRoleParts.join("|");
              }
            }
          });
        }
        return transformedData;
      });

      // Update the state with the new, processed data
      setData(dataSource);
      setReaderKey(Date.now());
    } else {
      message.error("Invalid CSV file. Please download CSV template");
    }
  };

  if (loading || !csvColumns) {
    return null;
  }
  return (
    <>
      <div
        style={{
          textAlign: "center",
        }}
      >
        {filterOptions && (
          <Space direction="vertical">
            {Object.keys(filterOptions)
              .sort()
              .map((filterOption, index) => (
                <Space key={`${filterOption}-${index}`}>
                  <Typography.Title level={5}>{filterOption}</Typography.Title>
                  <Radio.Group
                    onChange={({ target }: RadioChangeEvent) => {
                      // console.log(target.value)
                      changeFilter(
                        filterOption.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
                        target.value
                      );
                    }}
                    defaultValue=""
                  >
                    {filterOptions[filterOption].map((option, index) => (
                      <Radio.Button key={`${option}-${index}`} value={option}>
                        {option}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Space>
              ))}
          </Space>
        )}

        <Card bordered={false} bodyStyle={{ padding: "0" }}>
          <div id="container" style={{ marginLeft: "0px" }}>
            <Button
              type="primary"
              style={{ alignItems: "center" }}
              shape="round"
              icon={<DownloadOutlined />}
              // onClick={() => console.log(data, target, columns, 'data')}
            >
              <CSVLink style={{ color: "white", width: "100px" }} data={[csvColumns] as Data} filename="template.csv">
                Download CSV Template
              </CSVLink>
            </Button>

            {type !== "download" ? (
              <CSVReader
                key={readerKey}
                label={`Upload CSV for ${target} below`}
                onFileLoaded={handleUpload}
                parserOptions={papaparseOptions}
              />
            ) : (
              <Button
                type="primary"
                style={{ alignItems: "center", marginLeft: "30px" }}
                shape="round"
                icon={<DownloadOutlined />}
                // onClick={() => console.log(data, target, columns, 'data')}
              >
                <CSVLink
                  style={{ color: "white", width: "100px" }}
                  data={data}
                  filename={`${target}.csv`}
                  headers={columns?.map((col: any) => ({
                    label: col.title,
                    key: `${col.dataIndex}`,
                  }))}
                >
                  Download Current User List As CSV
                </CSVLink>
              </Button>
            )}
          </div>

          <div style={{ marginTop: "10px", marginLeft: "0px" }}>
            <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
          </div>
        </Card>
      </div>
      {prevButton}
      <Button type="primary" onClick={() => onComplete(data)}>
        {type == "update" ? "Update CSV" : nextStepTitle.split('|')[0]}
      </Button>
    </>
  );
}

export default WorkflowCSV;
