import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={() => history.push("/")} type="primary">
            Return to Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
