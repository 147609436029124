import { useState, useEffect } from 'react';
import { Descriptions } from 'antd';
import apiCaller from 'utils/apiCaller';
import _ from 'lodash';

interface Props {
  workflowId: string;
  csvStep: string;
}
const WorkflowCSVReport = ({ workflowId, csvStep }: Props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchValues() {
      const res = await apiCaller.get('/api/csv-subcollection', {
        params: { workflowId, csvStep },
      });
      if (res.data) {
        const dataSource = res.data.map((d: any) =>
          _.mapKeys(d, (value, key) => _.startCase(key))
        );
        console.log(dataSource);
        setData(dataSource);
      }
    }
    fetchValues();
  }, []);
  return (
    <div>
      {data.map((d: any) => (
        <Descriptions key={d.key}>
          {Object.keys(d)
            .sort()
            .map((key: any) => {
              if (key.toLowerCase() !== 'key') {
                return (
                  <Descriptions.Item key={key} label={key}>
                    {d[key]}
                  </Descriptions.Item>
                );
              }
            })}
        </Descriptions>
      ))}
    </div>
  );
};

export default WorkflowCSVReport;
