import React, { useState, useContext } from "react";
import { Menu, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import AdminRoute from "components/AdminRoute";
import StartWorkflowView from "views/StartWorkflowView";
import WorkflowView from "views/WorkflowView";
import { AuthContext } from "context/AuthProvider";

const { SubMenu } = Menu;

const HamburgerMenu = (props) => {
  const { allowedWorkflows } = props;
  const [visible, setVisible] = useState(false);
  const { activeUser } = useContext(AuthContext);
  const match = useRouteMatch();
  const isAdmin = activeUser.roles.includes("superadmin") || activeUser.roles.includes("admin");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleMenuItemClick = () => {
    onClose();
  };
  const uniqueSections = allowedWorkflows
    .filter((workflow) => {
      if (workflow.roles.includes("user")) return true;
      return activeUser?.roles.some((role) => workflow?.roles.includes(role));
    })
    .map((workflow) => workflow.section)
    .filter((v, i, s) => s.indexOf(v) === i)
    .sort();

  return (
    <>
      <div className="hamburger-menu" onClick={showDrawer}>
        <MenuOutlined />
      </div>
      <Drawer className="Navigation-bar" placement="left" closable={false} onClose={onClose} visible={visible}>
        <Menu mode="vertical">
          {isAdmin ? (
            <div>
              <Menu.Item key="main-dashboard">
                <Link to={`${match.path}/dashboard`} onClick={handleMenuItemClick}>
                  Dashboard
                </Link>
              </Menu.Item>
              <Menu.Item key="admin-dashboard">
                <Link to={`${match.path}/dashboard/admin`} onClick={handleMenuItemClick}>
                  Admin Dashboard
                </Link>
              </Menu.Item>
            </div>
          ) : (
            <Menu.Item key="main-dashboard">
              <Link to={`${match.path}/dashboard`} onClick={handleMenuItemClick}>
                Dashboard
              </Link>
            </Menu.Item>
          )}

          <SubMenu key="start-workflow" title="Start new workflow" onClick={handleMenuItemClick} mode="vertical">
            {uniqueSections.map((section) => (
              <Menu.Item key={section} onClick={handleMenuItemClick}>
                <Link to={`${match.path}/workflow/${section.toLowerCase().replace(/\s+/g, "-")}`}>{section}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
