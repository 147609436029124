import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/AuthProvider";
import moment, { Moment } from "moment";
import _ from "lodash";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { DownloadOutlined } from "@ant-design/icons";
import type { RangePickerProps } from "antd/es/date-picker";
import OurTable from "./workflow/OurTable";
import "../index.css";
import type { FilterValue, SorterResult, TableRowSelection } from "antd/es/table/interface";
import {
  Badge,
  Button,
  DatePicker,
  Descriptions,
  Drawer,
  Input,
  Modal,
  Space,
  Table,
  message,
  Card,
  Skeleton,
  TableProps,
} from "antd";

import apiCaller from "utils/apiCaller";
import "./workflow/DottyDisplay.css";
import { AdminTableProps, TableItem } from "models";
import Stepper from "./workflow/Stepper";
import Loader from "./Loader";
import PdfPreviewModal from "../components/workflow/PdfPreviewModal";

const JSZipUtils = require("jszip-utils");
const { Search } = Input;
const { Column } = Table;

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

const getNameAndEmail = (username: string, email: string) => {
  const divStyle = { fontSize: "10px" };
  const margin = { margin: "0px" };
  let shortenedEmail = "";

  if (username !== null && username !== undefined) {
    shortenedEmail = "(" + email.split("@cs.")[0] + "@cs)";
  }

  return (
    <div>
      {" "}
      <p style={margin}>{username}</p>
      <p style={divStyle}>{shortenedEmail}</p>
    </div>
  );
};

const getWFNameAndDateAsDiv = (text: string, record: any) => {
  const divStyle = { fontSize: "10px" };
  const margin = { margin: "0px" };
  const date = new Date(record.createdAt._seconds * 1000);
  const dateTime =
    "(" +
    `${date.toLocaleDateString()}` +
    " " +
    `${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}` +
    ")";

  return (
    <div>
      {" "}
      <p style={margin}>{text}</p>
      <p style={divStyle}>{dateTime}</p>
    </div>
  );
};

const AdminTable = ({ data, usersMap, handleChange }: AdminTableProps) => {
  const durationInSeconds=2;
  const { activeUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState<TableItem>();
  const [logs, setLogs] = useState<any[]>();
  const [actionsWtActor, setActions] = useState<any>({});
  const [filteredData, setFilteredData] = useState(data);
  const [filteredDataCheckBox, setFilteredDataCheckBox] = useState(data);
  const [datesSelected, setDatesSelected] = useState<[Moment, Moment] | undefined>();
  const [isAnyOtherFilterSelected, setIsAnyOtherFilterSelected] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [deleteBulkButtonDisplay, setDeleteBulkButtonDisplay] = useState<string>("hide");
  const [clearAllButtonDisplay, setClearAllButtonDisplay] = useState<string>("hide");
  const [searchText, setSearchText] = useState<string>("");
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TableItem>>({});
  const [display,setDisplay]=useState(true);
  const [allStepsInfo,setAllStepsInfo]=useState<any[]>();

  const getLogsWithActorInfo = async (record: any) => {
    setDisplay(true);
    const logs = await apiCaller.get("/api/workflow-log", {
      params: { workflowId: record.id },
    });

    let allLogs: any[] = logs.data;

    for (let i = allLogs.length - 1; i >= 0; i--) {
      let log = allLogs[i];
      if (log["type"] === "NEXT") {
        let desc: string[] = log["description"].split(" ");
        actionsWtActor[desc[desc.length - 1]] = {
          actor: log["user"],
          timestamp: log["timestamp"],
        };
      } else if (log["type"] === "CREATE") {
        actionsWtActor["createTimestamp"] = log["timestamp"];
      }
    }

    setActions(actionsWtActor);
    setRow(record);
    setAllStepsInfo(record.steps);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setDeleteBulkButtonDisplay("show");
    setClearAllButtonDisplay("show");
    setSelectedRowKeys(newSelectedRowKeys);
    if (newSelectedRowKeys.length === 0) {
      setDeleteBulkButtonDisplay("hide");
      if (datesSelected === undefined && searchText === "" && !isAnyOtherFilterSelected) {
        setClearAllButtonDisplay("hide");
      }
      setFilteredDataCheckBox(filteredData);
    } else {
      const filteredData = data.filter((record: any) => newSelectedRowKeys.some((b) => record.id === b));
      setFilteredDataCheckBox(filteredData);
    }
  };

  const loadLogs = async (record: any) => {
    const res = await apiCaller.get("/api/workflow-log", {
      params: { workflowId: record.id },
    });
    setLogs(res.data);
  };

  const showArchiveModal = (record: any) => {
    Modal.confirm({
      title: "Are you sure you want to archive this workflow?",
      onOk() {
        archiveWorkflow(record);
      },
    });
  };

  
  const archiveWorkflow = async (record: any) => {
    setLoading(true);
    const res = await apiCaller.post("/api/archive-workflow", {
      workflowId: record.id,
    });
    setLoading(false);
    if (res.status === 200) {
      message.success("Workflow successfully archived!",durationInSeconds);
      handleChange();
    } else {
      message.error("Workflow failed to archive.",durationInSeconds);
    }
  };

  const showDeleteModal = (record: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this workflow?",
      onOk() {
        deleteWorkflow(record);
      },
    });
  };
  
  const deleteWorkflow = async (record: any) => {
    setLoading(true);
    const res = await apiCaller.post("/api/delete-workflow", {
      workflowId: record.id,
    });
    setLoading(false);
    if (res.status === 200) {
      message.success("Workflow successfully deleted!",durationInSeconds);
      handleChange();
    } else {
      message.error("Workflow failed to delete.",durationInSeconds);
    }
  };

  const deleteWorkflowBulk = async (recordList: any) => {
    setLoading(true);
    const res = await apiCaller.post(
      "/api/bulk-delete-workflow",
      {
        workflowList: recordList,
      },
      { timeout: 600000 }
    );
    setLoading(false);
    if (res.status === 200) {
      message.success(selectedRowKeys.length.toString() + " workflow(s) successfully deleted!",durationInSeconds);
      handleChange();
    } else {
      message.error(selectedRowKeys.length.toString() + "workflow(s) failed to delete!",durationInSeconds);
    }
  };

  const filterAllDataForSortingText = (data: any) => (formatter: any) =>
    data.map((item: any) => ({
      text: formatter(item),
      value: formatter(item),
    }));

  const logColumns=[
    {
      title: "Type",
      dataIndex: "type",
      key: "type"},
    {
      title:"Timestamp",
      dataIndex:"timestamp",
      key:"timestamp" ,
    },
    {
      title:"User",
      dataIndex:"user",
      key:"user",
    },
    {
      title:"Actor",
      dataIndex:"actor",
      key:"actor",
    },  
    {
      title:"Description",
      dataIndex:"description",
      key:"description",
      render:(text: any, record: any) => {
              if (record.values) {
                return (
                  <>
                    <div>{text}</div>
                    <div>{JSON.stringify(record.values, null, 2)}</div>
                  </>
                );
              } else {
                return text;
              }
            }
    }
  ]
  const columns = [
    {
      title: "Workflow Name and Creation Date",
      dataIndex: "workflowFile",
      key: "workflowFile",
      filters: _.uniqWith(
        filterAllDataForSortingText(data)((i: any) => i.workflowFile),
        _.isEqual
      ),
      filterSearch: true,
      filterMultiple: true,
      filterMode: "menu",
      filteredValue: filteredInfo.workflowFile || null,
      onFilter: (text: any, record: any) => record.workflowFile.startsWith(text),
      sorter: (a: any, b: any) =>
        a.lastModified & b.lastModified
          ? b.lastModified._seconds - a.lastModified._seconds
          : b.createdAt._seconds - a.createdAt._seconds,
      sortOrder: sortedInfo.columnKey === "workflowFile" ? sortedInfo.order : null,

      render: (text: any, record: any) => {
        if (text !== null && text !== undefined) {
          const details = getWFNameAndDateAsDiv(text, record);
          return details;
        } else {
          return text;
        }
      },
    },
    {
      title: "Created By",
      dataIndex: "createdByEmailName",
      key: "createdByEmailName",
      filters: _.uniqWith(
        filterAllDataForSortingText(data)((i: any) => i.createdByEmailName[1]),
        _.isEqual
      ),
      filterSearch: true,
      filteredValue: filteredInfo.createdByEmailName || null,
      onFilter: (text: any, record: any) => record.createdByEmailName[1] === text,
      render: (text: any, record: any) => {
        if (text !== null && text[0] !== undefined && text[1] !== undefined) {
          const details = getNameAndEmail(text[1], text[0]);
          return details;
        } else {
          return text;
        }
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeEmailName",
      key: "assigneeEmailName",
      filterMultiple: true,
      filters: _.uniqWith(
        filterAllDataForSortingText(data)((i: any) => i.assigneeEmailName[1]),
        _.isEqual
      ),
      filterSearch: true,
      filteredValue: filteredInfo.assigneeEmailName || null,
      onFilter: (text: any, record: any) => record.assigneeEmailName[1] === text,
      render: (text: any[], record: any) => {
        if (text !== null && text[0] !== undefined && text[1] !== undefined) {
          const details = getNameAndEmail(text[1], text[0]);
          return details;
        } else {
          return text;
        }
      },
    },
    {
      title: "Current Assignee's Completion",
      dataIndex: "currentAssigneeComplete",
      key: "currentAssigneeComplete",
      render: (text: string, record: any) =>
        record.currentAssigneeComplete || record.workflowComplete ? (
          <Badge color="green" text="complete" />
        ) : (
          <Badge color="red" text="incomplete" />
        ),
    },
    {
      title: "Details",
      key: "details",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type="primary" onClick={() => getLogsWithActorInfo(record)}>
            View
          </Button>
          <Button type="primary" onClick={() => loadLogs(record)}>
            Logs
          </Button>
          <Button
            type="primary"
            onClick={() => showArchiveModal(record)}
          >
            Archive
          </Button>
          <Button
            style={{ backgroundColor: "#990000", borderColor: "#990000" }}
            type="primary"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  const actionButton = [
    {
      title: "Details",
      key: "details",
      render: (text: string, record: any) => (
        <Space size="middle" className="button-admin">
          <div className="button-admin-div">
          <Button type="primary" onClick={() => getLogsWithActorInfo(record)}>
            View
          </Button>
          <Button type="primary" onClick={() => loadLogs(record)}>
            Logs
          </Button>
          </div>
          <div className="button-admin-div">
          <Button
            type="primary"
            onClick={() => showArchiveModal(record)}
          >
            Archive
          </Button>
          <Button
            style={{ backgroundColor: "#990000", borderColor: "#990000" }}
            type="primary"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </Button>
          </div>
        </Space>
      ),
    },
  ];

  const rowSelection: TableRowSelection<TableItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const expandedRowRender = (record: any) => {
    const columnsInnerTable = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text: any[], record: any) => {
          return record.id;
        },
      },
      {
        title: "Last Modified",
        dataIndex: "lastModified",
        key: "lastModified",
        render: (text: any, record: any) => {
          if (record.lastModified) {
            const date = new Date(record.lastModified._seconds * 1000);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
          }
        },
      },
      {
        title: "Current Step",
        dataIndex: "currentStep",
        key: "currentStep",
        render: (currentStep: string, workflow: any) => {
          for (const step of workflow.workflowSteps) {
            if (step["step"] === currentStep) return step["stepNls"].split('|')[0];
          }

          return "";
        },
      },
    ];

    const historyData = data.filter((row: any) => row.id === record.id);
    return <Table columns={columnsInnerTable} dataSource={historyData} pagination={false} />;
  };

  const onChangeForTable: TableProps<TableItem>["onChange"] = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    type ObjectKey = keyof typeof sorter;
    const myVar = "order" as ObjectKey;
    if (sorter[myVar] === "descend") {
      setClearAllButtonDisplay("show");
    }
    setSortedInfo(sorter as SorterResult<TableItem>);

    if (selectedRowKeys.length === 0) {
      if (
        extra.currentDataSource !== null &&
        (filters.assigneeEmailName !== null || filters.createdByEmailName !== null || filters.workflowFile !== null)
      ) {
        setIsAnyOtherFilterSelected(true);
        setClearAllButtonDisplay("show");
        setFilteredDataCheckBox(extra["currentDataSource"]);
      } else if (
        extra.currentDataSource === null &&
        (filters.assigneeEmailName === null || filters.createdByEmailName === null || filters.workflowFile === null)
      ) {
        setFilteredData(data);
      }

      if (
        extra.currentDataSource !== null &&
        sorter[myVar] === undefined &&
        filters.assigneeEmailName === null &&
        filters.createdByEmailName === null &&
        filters.workflowFile === null
      ) {
        setIsAnyOtherFilterSelected(false);
        if (datesSelected === undefined && searchText === "") {
          setClearAllButtonDisplay("hide");
          setFilteredData(data);
          setFilteredDataCheckBox(data);
        } else {
          handleDatePick(datesSelected);
        }
      }
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClearAllButtonDisplay("show");
    const filteredData = data.filter((column: any) =>
      Object.keys(column).some((v: any) => {
        if (typeof column[v] === "string" && v === "workflowFile") {
          return `${column[v]}`.toLowerCase().includes(e.target.value.toLowerCase());
        } else if (typeof column[v] === "object" && v === "createdByEmailName") {
          return `${column[v][1]}`.toLowerCase().includes(e.target.value.toLowerCase());
        } else if (typeof column[v] === "object" && v === "assigneeEmailName") {
          return `${column[v][1]}`.toLowerCase().includes(e.target.value.toLowerCase());
        }

        return false;
      })
    );
    setSearchText(e.target.value.toLowerCase());
    setFilteredData(filteredData);
    setFilteredDataCheckBox(filteredData);
  };

  const handleDatePick = (dates: any) => {
    if (dates && selectedRowKeys.length === 0) {
      setClearAllButtonDisplay("show");
      const [startDate, endDate] = dates;
      startDate.startOf("day");
      endDate.endOf("day");
      let newData = data;
      if (data.length !== filteredData.length && selectedRowKeys.length === 0) {
        newData = filteredData;
      }
      if (newData.length === 0) {
        newData = data;
      }
      const filteredDataDateHandler = newData.filter((record: any) => {
        const localTime = new Date(record.createdAt._seconds * 1000);
        // Convert time to GMT (DatePicker uses GMT)
        localTime.setTime(localTime.getTime() + localTime.getTimezoneOffset() * 60 * 1000);
        const date = moment(localTime);
        if (date >= startDate && date <= endDate) {
          return true;
        }
        return false;
      });
      setFilteredData(filteredDataDateHandler);
      setFilteredDataCheckBox(filteredDataDateHandler);
      setDatesSelected(dates);
    } else {
      if (isAnyOtherFilterSelected) {
        setFilteredData(filteredData);
      } else {
        setFilteredData(data);
        setFilteredDataCheckBox(data);
      }
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current > moment().endOf("day");
  };

  const clearAllFilters = () => {
    setFilteredData(data); // Reset data to be displayed
    setFilteredDataCheckBox(data); // Reset data to be downloaded
    setFilteredInfo({}); // Reset any column filters selected
    setSearchText(""); // Set search box text to empty string
    setDatesSelected(undefined); // Set selected dates to []
    setSelectedRowKeys([]); // Reset checkboxes selected to 0
    setClearAllButtonDisplay("hide"); // Hide the Clear filters button
    setDeleteBulkButtonDisplay("hide"); // Hide the delete bulk WF button
    setSortedInfo({}); // Reset Sort order
  };

  const showDeleteModalBulk = () => {
    if (selectedRowKeys.length > 0) {
      Modal.confirm({
        title: `Are you sure you want to delete ${selectedRowKeys.length.toString()} selected workflow(s)?`,
        content: "Note: Delete(s) cannot be reverted!",
        onOk() {
          deleteWorkflowBulk(selectedRowKeys);
        },
      });
    } else {
      message.error("No workflow(s) selected to delete",durationInSeconds);
    }
  };

  const showDownLoadModalBulk = () => {
    if (filteredDataCheckBox.length > 50) {
      message.error("Please select <50 workflows at a time to download PDF(s).",durationInSeconds);
    } else {
      downloadArchive();
    }
  };

  const getFormattedTime = () => {
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const h = today.getHours();
    const mi = today.getMinutes();
    const s = today.getSeconds();
    return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
  };

  const mergeById = (a1: any, a2: any) =>
    a1.map((itm: any) => ({
      ...a2.find((item: any) => item.worflowID === itm.id && item),
      ...itm,
    }));

  function timeConverter(UNIX_timestamp: any) {
    const a = new Date(UNIX_timestamp * 1000);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();
    const time = date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  const downloadExcel = async (WFfileName: any, mergedObjectsToCSV: any) => {
    interface workflowFormsCSVData {
      WFName: string;
      blobData: any;
    }
    const separator = ",";
    const keys = Object.keys(mergedObjectsToCSV[0]);
    const csvContent =
      keys.join(separator) +
      "\n" +
      mergedObjectsToCSV
        .map((row: any) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? "" : row[k];

              if (typeof cell === "object" && cell !== null && cell["_seconds"] !== undefined) {
                cell = JSON.stringify(timeConverter(cell["_seconds"]));
              } else if (typeof cell === "object") {
                cell = JSON.stringify(cell).replaceAll(",", "-");
              } else {
                cell = cell.toString();
              }
              return cell;
            })
            .join(separator);
        })
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const element: workflowFormsCSVData = {
      WFName: WFfileName,
      blobData: blob,
    };
    return element;
  };

  const getFormData = async (idsList: any) => {
    if (idsList.length > 0) {
      setLoading(true);
      const res = await apiCaller.post(
        "/api/get-all-form-data-batched",
        {
          workflowList: idsList,
        },
        { timeout: 600000 }
      );
      setLoading(false);
      const allData = res.data;
      let mergedObjectsToCSV = mergeById(filteredDataCheckBox, allData);
      let newmergedList = _.groupBy(mergedObjectsToCSV, "workflowFile");
      var blobList: any[] = [];
      for (const property in newmergedList) {
        blobList.push(await downloadExcel(property, newmergedList[property]));
      }
      return blobList;
    }
  };

  const downloadArchive = async () => {
    try {
      const idsList = filteredDataCheckBox.map((obj: any) => obj.id);
      var zip = new JSZip();
      var countPDF = 0;
      var countForm = 0;
      var zipFilename = "Admin_PDF_Form_" + getFormattedTime() + ".zip";

      if (idsList.length > 0 && idsList.length < 49) {
        setLoading(true);
        const res = await apiCaller.post(
          "/api/download-pdf-bulk",
          {
            workflowList: idsList,
          },
          { timeout: 600000 }
        );
        setLoading(false);
        const allData = res.data;
        var urls: any[] = [];
        for (const WF of allData) {
          const workflowId = WF.worflowID;
          const UUIDList = WF.UUIDsListOfStrings;
          for (const [, items] of UUIDList.entries()) {
            let finalKey = workflowId + "_" + items?.uuid;
            var demo = {
              id: finalKey,
              fileLink: items,
            };
            urls.push(demo);
          }
        }

        urls.forEach(function (url, index) {
          var filename = "file-" + url.id + ".pdf";
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(
            typeof url.fileLink === "object" ? url.fileLink?.urlOfPDF : url.fileLink,
            async function (err: any, data: any) {
              if (err) {
                throw err;
              }
              zip.file(filename, data, { binary: true });
              countPDF++;
            }
          );
        });

        let blobList = await getFormData(idsList);

        if (blobList !== undefined) {
          blobList.forEach(async function (url, index) {
            var filename = `Admin_${url.WFName}_` + getFormattedTime() + `.csv`;
            // loading a file and add it in a zip file
            zip.file(filename, url.blobData, { binary: true });
            countForm++;
          });
        }
        if (blobList !== undefined) {
          if (countPDF === urls.length && countForm === blobList.length) {
            var zipFile = await zip.generateAsync({ type: "blob" });
            saveAs(zipFile, zipFilename);
          }
        }
      } else {
        let blobList = await getFormData(idsList);

        if (blobList !== undefined) {
          blobList.forEach(async function (url, index) {
            var filename = `Admin_${url.WFName}_` + getFormattedTime() + `.csv`;
            // loading a file and add it in a zip file
            zip.file(filename, url.blobData, { binary: true });
            countForm++;
          });
        }
        if (blobList !== undefined) {
          if (countForm === blobList.length) {
            var zipFileForm = await zip.generateAsync({ type: "blob" });
            saveAs(zipFileForm, zipFilename);
          }
        }
      }
    } catch (e) {
      message.error("Unable to download data!",durationInSeconds);
    }
  };
  const onExpand = async (expanded: any, record: any) => {};

  if (loading) {
    return (
      <Card bordered={false}>
        <Loader />
        <Skeleton active />
      </Card>
    );
  }

  return (
    <>
      <div className="search-section">
        <div className="sub-search-section">
          <Search
            placeholder="Search By WorkFlow Name / User Name"
            onChange={handleSearch}
            allowClear={true}
            value={searchText}
          />
        </div>
        <div id="container" className="sub-search-section">
          <Button
            type="primary"
            style={{ marginBottom: "10px", minWidth: "120px"}}
            shape="round"
            className={clearAllButtonDisplay}
            onClick={() => clearAllFilters()}
          >
            Reset Filters
          </Button>

          <Button
            type="primary"
            style={{ marginBottom: "10px", minWidth: "120px"}}
            shape="round"
            className={deleteBulkButtonDisplay}
            onClick={() => showDeleteModalBulk()}
          >
            Delete WF(s)
          </Button>

          <Button
            type="primary"
            style={{ marginBottom: "10px", minWidth: "120px"}}
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => showDownLoadModalBulk()}
          >
            Download
          </Button>
        </div>
        <div className="sub-search-section">
          <DatePicker.RangePicker
            onChange={handleDatePick}
            allowClear={false}
            value={datesSelected}
            disabledDate={disabledDate}
          />
        </div>
      </div>
      
      {/* <Table
        columns={columns}
        expandable={{ expandedRowRender, onExpand }}
        dataSource={filteredData}
        rowSelection={rowSelection}
        onChange={onChangeForTable}
      /> */}
      <OurTable
        data={filteredData}
        columns={columns}
        expandable={{ expandedRowRender, onExpand }}
        actionButton={actionButton}
        activeUser={activeUser}
        rowSelection={rowSelection}
        onChange={onChangeForTable}
      ></OurTable>

      <Modal centered visible={!!logs} onCancel={() => setLogs(undefined)} width="auto" footer={null}>
        <OurTable className="log-table" data={logs} columns={logColumns} activeUser={activeUser}/>
        {/* <Table dataSource={logs} style={{tableLayout: "fixed"}} pagination={{ pageSize: 7 }}>
          <Column title="Type" dataIndex="type" key="type" />
          <Column title="Timestamp" dataIndex="timestamp" key="timestamp" />
          <Column title="User" dataIndex="user" key="user" />
          <Column title="Actor" dataIndex="actor" key="actor" />
          <Column
            title="Description"
            dataIndex="description"
            key="description"
            render={(text, record: any) => {
              if (record.values) {
                return (
                  <>
                    <div>{text}</div>
                    <div>{JSON.stringify(record.values, null, 2)}</div>
                  </>
                );
              } else {
                return text;
              }
            }}
          />
        </Table> */}
      </Modal>
      <div onClick={()=>{setDisplay(!display)}}>
        {display &&
        <Drawer width="auto" visible={!!row} onClose={() => setRow(undefined)} closable={false}>
    
        <Descriptions title={row?.name} bordered>
          <Descriptions.Item label="Workflow Completion" span={3}>
            {row?.workflowComplete ? <Badge color="green" text="complete" /> : <Badge color="red" text="incomplete" />}
          </Descriptions.Item>
          <Descriptions.Item label="Created By" span={3}>
            {row?.createdBy}
          </Descriptions.Item>
          <Descriptions.Item label="Current Assignee" span={3}>
            {row?.currentAssignee}
          </Descriptions.Item>
          <Descriptions.Item label="Current Assignee's Completion" span={3}>
            {row?.currentAssigneeComplete ? (
              <Badge color="green" text="complete" />
            ) : (
              <Badge color="red" text="incomplete" />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Last Reminded" span={3}>
            {row?.lastReminded?.toDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="Current Step" span={3}>
            {row?.currentStep}
          </Descriptions.Item>
          <Descriptions.Item label="Last Edited" span={3}>
            {row?.lastEdited?.toDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="Progress Bar" span={3}>
            <Stepper
              workflowSteps={row?.workflowSteps}
              allStepsInfo={allStepsInfo}
              currentStep={row?.currentStep}
              wfUsers={row?.users}
              usersMap={usersMap}
              logActions={actionsWtActor}
              currAssignee={row?.currentAssignee as string}
              PdfPreviewProp={PdfPreviewModal}
              showWfStepsStatus={true}
            />
          </Descriptions.Item>
        </Descriptions>
     
      </Drawer>}
      </div>
    </>
  );
};

export default AdminTable;
