import axios from "axios";
import axiosRetry from "axios-retry";
import auth from "utils/firebaseSetup";

const DEV_HOST = "http://localhost:5001/cs-workflow-304601/us-central1";
const PROD_HOST = "https://workflow.cs.stonybrook.edu";

// const HOST = DEV_HOST;
const HOST = PROD_HOST;

const defaultInstance = {
  baseURL: HOST,
  timeout: 100000,
};

const apiCaller = axios.create(defaultInstance);
axiosRetry(apiCaller, { retries: 3 });

apiCaller.interceptors.request.use(async (config) => {
  const idTokenResult = await auth.currentUser?.getIdTokenResult();
  const token = idTokenResult?.token;
  config.headers.authorization = token ? `Bearer ${token}` : "";
  return config;
});
export default apiCaller;
