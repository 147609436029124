import React, { useContext, useState, useEffect } from 'react';
import { Form } from 'antd';
import FormBuilder from 'antd-form-builder';
import { AuthContext } from 'context/AuthProvider';
import moment from 'moment';
import _ from 'lodash';
import {
  Button
} from "antd";
interface Props {
  options: any;
  onFinish: (option: string) => void;
}
const WorkflowSelect = ({ options, onFinish }: Props) => {
  const [form] = Form.useForm();
  // console.log(form, 'form');
  const forceUpdate = FormBuilder.useForceUpdate();
  // console.log(forceUpdate, 'forceUpdate')
  const [meta, setMeta] = useState(options);
  // console.log(' Inside WorkflowSelect', options , onFinish);


  const handleFinish = (values: any) => {
    if (values.target) {
      onFinish(`${values.action} ${values.target}`);
    } else {
      onFinish(values.action);
    }
  };

  return (
    <Form
      form={form}
      onValuesChange={() => forceUpdate()}
      layout='vertical'
      id='select-form'
      onFinish={handleFinish}
    >
      <FormBuilder meta={meta} form={form} />
      <Form.Item  className="form-footer">
          <Button style = {{'float':'left' }} htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
    </Form>
  );
};

export default WorkflowSelect;
