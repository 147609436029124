import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import { message } from "antd";

import apiCaller from "utils/apiCaller";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

!getApps().length ? initializeApp(config) : getApp();

interface ContextProps {
  currentUser: any | null;
  activeUser: any | null;
  authenticated: boolean;
  setCurrentUser: any;
  loadingAuthState: boolean;
  redirectURL: string;
  setRedirectURL: any;
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});

const auth = getAuth();

export const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<any | null>(); // the logged in user
  const [activeUser, setActiveUser] = useState<any | null>(); // defaults to logged in user, can be the impersonated user
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const durationInSeconds=2;
  const history = useHistory();

  useEffect(() => {
    onIdTokenChanged(auth, async (user) => {
      if (!user) {
        history.push("/");
        return;
      }

      try {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const res = await apiCaller.get("/api/user", {
          params: { email: user?.email },
        });

        const impersonatedUserId = idTokenResult?.claims.impersonatedUserId ?? "";
        const userObject = {
          ...res.data,
          ...user,
          phoneNumber:res.data?.phoneNumber,// To be removed after issue check of firebase token 
          impersonatedUserId,
        };
        
        if (user?.email !== res.data?.email && res.data?.email) {
          userObject.email = res.data?.email;
        }

        // Set activeUser if currentUser is impersonating someone else
        if (impersonatedUserId !== "") {
          const res = await apiCaller.get("/api/impersonated-user", {
            params: { impersonatedUserId },
          });
          message.success(`You are impersonating ${res.data.name} (${res.data.email})!`,durationInSeconds);
          setActiveUser({
            ...res.data,
          });
        } else {
          setActiveUser(userObject);
        }

        setCurrentUser(userObject);
        setLoadingAuthState(false);
      } catch (err) {
        console.error("Error in authentication: ", err);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        activeUser,
        authenticated: currentUser !== null,
        setCurrentUser,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
