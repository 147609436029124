import React, { useContext } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import ReactLoading from 'react-loading';

interface Props {
  component: React.ComponentType<RouteComponentProps<any>>;
  path?: string;
}

const AdminRoute = ({ component: Component, ...rest }: Props) => {
  const { currentUser, loadingAuthState } = useContext(AuthContext);

  const isAdmin =
    currentUser.roles.includes('superadmin') ||
    currentUser.roles.includes('admin');

  // loading auth state may be unnecessary since AdminRoutes only exist in the main /app PrivateRoute
  if (loadingAuthState) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <h1>Loading...</h1>
        <ReactLoading type='spinningBubbles' color='#990000' />
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAdmin ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to='/app/dashboard' />
        )
      }
    />
  );
};

export default AdminRoute;
