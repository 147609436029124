import { Route, Switch, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import "antd/dist/antd.css";

import { AuthProvider } from "context/AuthProvider";
import PrivateRoute from "components/PrivateRoute";
import ApplicationPage from "pages/ApplicationPage";
import LoginPage from "pages/LoginPage";
import NotFoundPage from "pages/NotFoundPage";
import CodeRetrievalPage from "pages/CodeRetrievalPage";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
  },
});

const Content = () => {
  return (
    <Switch>
      <PrivateRoute path="/app" component={ApplicationPage} />
      <NotFoundPage />
    </Switch>
  );
};

const App = () => {
  const classes = useStyles();
  const history = useHistory();
  const redirectURL = history.location.pathname;

  // TODO: Update the Direct Start with redirect URL with a new view
  const directPath = "/app/dashboard/direct-start/";
  if (redirectURL.startsWith(directPath)) {
    localStorage.setItem("redirectURL", decodeURIComponent(redirectURL.split(directPath)[1]));
  }

  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/adobe-code" component={CodeRetrievalPage} />
        {/* <Route exact path="/" render={(props) => <LoginPage {...props} redirectURL={redirectURL} />} /> */}
        <Route exact path="/" component={LoginPage} />
        <div className={classes.root}>
          <Content />
        </div>
      </Switch>
    </AuthProvider>
  );
};

export default App;
