import { ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, Result, Skeleton } from "antd";

import apiCaller from "utils/apiCaller";
import Loader from "components/Loader";

const CodeRetrievalPage = () => {
  const history = useHistory();
  const [flag, setFlag] = useState("invalid");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState<ReactNode>("");
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.toString() === "") {
      // Link which needs to be redirected to get the authorization code from the DoIT Adobe account.
      const adobe_link = `https://secure.na4.adobesign.com/public/oauth/v2?redirect_uri=https://workflow.cs.stonybrook.edu/adobe-code&response_type=code&client_id=CBJCHBCAABAAqZtmJqFtW6iIhVf9ySQdiVq5rIj3peGz&scope=user_read:self+user_write:self+user_login:self+agreement_read:self+agreement_write:self+agreement_send:self+widget_read:self+widget_write:self+library_read:self+library_write:self+webhook_read:self+webhook_write:self+webhook_retention:self`;
      setShouldRedirect(true);
      window.location.replace(adobe_link);
    } else {
      setShouldRedirect(true);
      if (queryParams.has("code")) {
        const authorization_code = queryParams.get("code");
        retrieveTokens(authorization_code);
      } else if (queryParams.has("error")) {
        const content = queryParams.get("error_description");
        displayContent(content);
      }
    }
  }, [location.search]);

  const displayContent = (
    item: any,
    auth_code?: any,
    access_token?: any,
    refresh_token?: any,
    api_access_point?: any,
    web_access_point?: any,
    token_type?: any,
    expires_in?: any
  ) => {
    if (item === "code") {
      setFlag("valid");
      setTitle("Successfully Received Adobe Authorization code and generated Tokens");
      setSubTitle(
        `The adobe authorization code ${auth_code} is used for generating the Refresh and Access tokens.${"\n"}
        The following are generated${"\n"}
        Access token: ${access_token} ${"\n"}
        Refresh token: ${refresh_token} ${"\n"}
        API Access point: ${api_access_point} ${"\n"}
        Web Access point: ${web_access_point} ${"\n"}
        Token type: ${token_type} ${"\n"}
        Expires in: ${expires_in} ${"\n"}
        All the above details are sent to the dev team.
        `
      );
      setShouldRedirect(false);
    } else {
      setFlag("invalid");
      setTitle("Access Denied. Adobe Authorization code not generated");
      setSubTitle(`${item}.`);
      setShouldRedirect(false);
    }
  };

  const retrieveTokens = async (authCode: any) => {
    const resp = await apiCaller.post("/api/retrieve-adobe-token", { auth_code: authCode });
    const { auth_code, access_token, refresh_token, api_access_point, web_access_point, token_type, expires_in } =
      resp.data;

    displayContent(
      "code",
      auth_code,
      access_token,
      refresh_token,
      api_access_point,
      web_access_point,
      token_type,
      expires_in
    );
  };

  if (shouldRedirect) {
    return (
      <Card bordered={false}>
        <Loader />
        <Skeleton active />
      </Card>
    );
  }

  return (
    <div>
      <Result
        status={flag === "valid" ? "success" : "error"}
        title={title}
        subTitle={subTitle}
        extra={
          <Button onClick={() => history.push("/app")} type="primary">
            Return to Home
          </Button>
        }
      />
    </div>
  );
};

export default CodeRetrievalPage;
