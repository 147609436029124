import { useState, useEffect } from 'react';
import { Descriptions, Table, Badge, message } from 'antd';
import apiCaller from 'utils/apiCaller';
import _ from 'lodash';

interface Props {
  workflowId: string;
  workflowFile: string;
}
const WorkflowReport = ({ workflowId, workflowFile }: Props) => {
  const [data, setData] = useState([]);
  const durationInSeconds=2;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'workflowFile',
      key: 'workflowFile',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any, record: any) => {
        const date = new Date(record.createdAt._seconds * 1000);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      title: 'Last Accessed',
      dataIndex: 'lastAccessed',
      key: 'lastAccessed',
      render: (text: any, record: any) => {
        const date = new Date(record.lastAccessed._seconds * 1000);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    { title: 'Created By', dataIndex: 'createdBy', key: 'createdBy' },
    {
      title: 'Current Assignee',
      dataIndex: 'currentAssignee',
      key: 'currentAssignee',
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
    },
    {
      title: "Current Assignee's Completion",
      dataIndex: 'currentAssigneeComplete',
      key: 'currentAssigneeComplete',
      render: (text: string, record: any) =>
        record.currentAssigneeComplete || record.workflowComplete ? (
          <Badge color='green' text='complete' />
        ) : (
          <Badge color='red' text='incomplete' />
        ),
    },
    {
      title: 'Workflow Completion',
      dataIndex: 'workflowComplete',
      key: 'workflowComplete',
      render: (text: string, record: any) =>
        record.workflowComplete ? (
          <Badge color='green' text='complete' />
        ) : (
          <Badge color='red' text='incomplete' />
        ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: any) => (
        <a onClick={() => remindUser(record.id)}>Remind</a>
      ),
    },
  ];

  useEffect(() => {
    async function fetchValues() {
      const res = await apiCaller.get('/api/workflow-report', {
        params: { workflowFile },
      });
      if (res.data) {
        // console.log(res.data);
        setData(res.data);
        // const dataSource = res.data.map((d: any) =>
        //   _.mapKeys(d, (value, key) => _.startCase(key))
        // );
        // console.log(dataSource);
        // setData(dataSource);
      }
    }
    fetchValues();
  }, []);

  const remindUser = async (workflowId: string) => {
    await apiCaller.post('/api/remind-workflow', { workflowId });
    message.success('Reminded user successfully!',durationInSeconds);
  };

  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default WorkflowReport;
