import React, { useEffect, useRef, useState } from 'react';
import { Modal, Input } from 'antd';

interface HelpModalProps {
  helpModalVisible: boolean;
  setHelpModalVisible: (visible: boolean) => void;
  sendDataToParent: (message:string) => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ helpModalVisible, setHelpModalVisible ,sendDataToParent  }) => {
//   const [helpModalMessage, setHelpModalMessage] = useState(''); // State to store the message from textarea
    const helpModalMessage=useRef("");
  const closeHelpModal = () => {
    helpModalMessage.current=""; //Reset value once used
    setHelpModalVisible(false);
  }
  const sendHelpRequest = () => {
    // console.log("sendHelpRequest value:", helpModalMessage.current);
    sendDataToParent(helpModalMessage.current);
    closeHelpModal(); // Close the modal after sending the message or adjust based on the response/callback
  };
  useEffect(() => {
    // You can update this variable anytime without causing a re-render
    helpModalMessage.current = 'new value';
  }, []); 
  const handleSetMessage = (message: string) => {
    // console.log("handleSetMessage value:", message);
    helpModalMessage.current=(message); // Update the state with the new message
  };

  return (
    <Modal
  visible={helpModalVisible}
  title="Need help? Send a message and we'll get back to you!"
  onOk={sendHelpRequest}
  onCancel={closeHelpModal}>
    
      <HelpTextArea setParentMessage={handleSetMessage} />
    </Modal>

  );
};
export default HelpModal; 

interface HelpTextAreaProps {
    setParentMessage: (message: string) => void;
  }
  const getValueFromSpanElement=(span:HTMLSpanElement|null)=>{
    let textareaValue = "";
    if (span){
        const textareas = span?.getElementsByTagName('textarea');
        const textareaValue = textareas[0].value; // Access the first textarea
        return textareaValue
    }
     return  textareaValue
  }
  const HelpTextArea: React.FC<HelpTextAreaProps> = ({ setParentMessage }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [textValue, setTextValue] = useState("");
    const handleModalClose = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextValue(event.target.value);
        if (textAreaRef.current) {
            const actualValue = getValueFromSpanElement(textAreaRef.current)
            // console.log("Submitting value:", actualValue);
          setParentMessage(actualValue);
          setTextValue("");
        }
      };
      const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextValue(event.target.value); // Update state immediately on change
        // setParentMessage(event.target.value); // Optionally send data to parent on each change
      };
    return (
        <span ref={textAreaRef}>
      <Input.TextArea
        value={textValue}
        rows={4}
        onBlur={handleModalClose}
        onChange={handleChange} // Update parent state when the text area loses focus
      />
      </span>
    );
  };