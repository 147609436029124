import { Button, Checkbox, Form } from "antd";

const WorkflowChecklist = ({
  checkList,
  currentStep,
  onWorkflowChecklistClick,
  onWorkflowChecklistNext,
}: any) => {
  const isDone = checkList.every((checkbox: any) => checkbox.done);
  const currentActiveStep = checkList.find((step: any) => step.done === false);
  // console.log("SHUBHAM: ", currentStep)
  return (
    <>
      <Form onFinish={() => onWorkflowChecklistNext()}>
        {checkList.map((checkbox: any, index: any) => (
          <Form.Item
            key={index}
            name={checkbox.name}
            initialValue={checkbox.done}
          >
            <div style={{width:"100%", display: "flex", justifyContent:"space-between"}}>
            <Checkbox checked={checkbox.done}>{checkbox.name}</Checkbox>
            <Button
              type="primary"
              shape="round"
              size={"small"}
              style={{ marginLeft: "15px" }}
              disabled={checkbox.done || (!checkbox.done && currentActiveStep !== checkbox)}
              onClick={() => onWorkflowChecklistClick()}
            >
              Open Step
            </Button>
            </div>
          </Form.Item>
        ))}
        <Form.Item>
          <Button
            disabled={!isDone}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginTop: "20px" }}
          >
            {currentStep?.nextStepTitle !== ""
              ? `${currentStep?.nextStepTitle.split("|")[0]}`
              : null}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default WorkflowChecklist;
